.background{
  position:static;
  width: 100%;
  bottom: 100%; 
  text-align: center;
    background: #fff;
    color:  rgb(45, 47, 158); 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


.imagesize{
  width:100%;
  position:relative;
  right:0;
}

.text{
  display: block;
  text-align: left;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight:300;
  letter-spacing: 1px;
}

.flex{
  position: static;
  border:none!important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0!important;
}
.colFlex1{
  display: flex;
  justify-content: flex-end;
  flex-direction: column!important;
  color:  rgb(6, 7, 80); 
  margin-top: auto;
  margin-bottom: auto;
  padding:0!important;
  height:70vh;  
  background:url('../img/profile.jpg') no-repeat center;
  background-size: cover; 
}
.colFlex2{
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column!important;
  background:  rgb(6, 7, 80); 
  color: #fff;
  padding-right:10em;
  padding-left:3em;
}

.header{
  align-self: flex-start;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  font-size: 3em;
  margin:0;
  padding-bottom: 0.5em;
}



@media only screen and (min-width: 769px) {
  .colFlex1{
    height:100vh;
  }

  .colFlex2{
    height:100vh;
  }

  .imagesize{
    height:100vh;
    width:100vh;
  }
.mobileHeader{
  display:none;
}
}

@media only screen and (max-width: 768px) {
  .colFlex2{
      padding: 3em;
  }
  .text{
      text-align: center;
      font-size: 18px;
      margin-bottom: 1em;
      letter-spacing: none;
  }
  .header{
    display:none;
  }
  
.mobileHeader{
  position: relative;
  bottom:1.1em;
  right:0;
  font-weight: 400;
  font-family: 'Abril Fatface', cursive;
  font-size: 5.3em;
  margin:0;
  padding-bottom: 0;
  height:0;
}
}