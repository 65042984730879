.background{
  position:static;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #2d2f9e; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.imagesize{
  margin-bottom: 1em;
  height: 128px;
  width: 128px;
}

.rowFlex{
  justify-content: space-around;
}

.colFlex{
  padding: 4em !important;
}

.cardDesign {
  padding: 1.8em;
   background: none;
   border: 4px solid #ffffff;
   color: white;
   font-family: "Montserrat", sans-serif;
   align-items: center;
   display: block;
   height: 100%;
   letter-spacing: 0.03em;
   border-radius: 2%;
}

.title{
  font-size: 1.5em;
}



@media only screen and (max-width: 991px) {
  .colFlex{
    padding-bottom: 0 !important;
  }

  .colFlex:last-child{
    padding-bottom: 4em !important;
  }
}


@media only screen and (max-width: 328px) {
  .imagesize{
    margin-bottom: 1em;
    height: 30vw;
    width: 30vw;
  }
  
.title{
  font-size: 1em;
}
}