
.background{
    position:relative;
    width: 100%;
    min-height: 100vh;
    text-align: center;
    border:none;
    display:flex;
}

.flex{
    position: absolute;
    z-index: 2;
    top: 0;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height:100vh;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: inset 100vh 100vw rgba(255, 255, 255, 0.5);
}

.text{
    font-family: 'Raleway', sans-serif; 
    font-size: 3.2em;
    color: #0d0f8f;
    opacity:0.8;
    letter-spacing: 0.04em;
    display: block;
    align-content: center;
    margin-top: auto;
    font-weight: 400;
    text-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.5);
}


