$base-color:rgb(0, 217, 255);

.background{
    position:static;
    bottom: 100%;
    width: 100%;
    min-height:90vh;
    background: #252934; 
    color: #fff;
    font-family: 'Raleway', sans-serif; 
    text-align: center;
}

.formField{
    font-size: 16px !important;
    display: block;
    width:100%;
    margin:1em 0;
    padding:0.5em;
    font-size: inherit;
    font-family: 'Raleway', sans-serif;
    width: 100% !important;
}
.formField:focus{
    outline: none;
    
}


.buttonCustom{
    background: $base-color;
    border:none;
    padding:0.5em 1em;
    color:#fff;
    font-family: 'Raleway', sans-serif; 
    box-shadow: inset 0 0 5rem rgba(0, 0, 0, 0.2);
    letter-spacing: 1px;
    font-size: inherit;
}

.buttonCustom:hover{
    background: #FFF;
    color: $base-color;
    box-shadow: none;
}

.thankYou{
    color: $base-color;
}

.smallText{
    font-size: 14px;
    margin: 1em 0;
    display: block;
}

.header{
    font-size: 3em;
    margin:0;
}

.grid{
    display:flex;
    flex-direction: column;
    justify-content: center;
    min-height:90vh;
    align-content: center;
}

.formGrid{
    display:flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}